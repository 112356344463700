.category-list {
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px 10px 0;
  grid-gap: 10px;

  .category-box {
    display: flex;
    align-items: flex-end;
    width: 199px;
    min-height: 160px;
    border-radius: 4px;
    cursor: pointer;

    .category-name {
      width: 100%;
      text-align: center;

      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
        letter-spacing: 0.02em;
      }
    }
  }

  .child-category {
    width: 160px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
}
