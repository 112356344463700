@import './variables.scss';

.keyboard-container {  
  margin: 8px;
  min-width: 500px;
}


.keyboard-input-container {
  display: flex;
  border: 2px solid transparent;    
  border-radius: 4px;
  background-color: #d6d6d6;
  color: #ffffff;
  font-family: inherit;
}

.keyboard-input-text {  
  color: #000000;
  font-family: inherit;
  border-bottom:none;
  padding: 0.75rem 0.75rem 0.75rem 0.5rem;
  width: 100%;
}

