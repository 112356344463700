@import './variables.scss';

.search-container {
    display: flex;
    flex-direction: column;
    opacity: 70%;
    overflow: hidden;
    min-width: 300px;
    width: 550px;
}

.search-input-container {
    display: flex;
    border: 2px solid transparent;
    border-radius: 4px;
    background-color: #d6d6d6;
    color: #ffffff;
    font-family: inherit;
    margin: 8px;

    &:focus {
        outline: none;
    }
}

.search-input-text {
    color: #000000;
    font-family: inherit;
    border-bottom: none;
    padding: 0.75rem 0.0rem 0.75rem 0.5rem;

    &:focus {
        outline: none;
    }
}

.search-input-icon {
    cursor: pointer;
    flex-shrink: 0;
    margin: 5px;

    svg {
        height: 100%;
        vertical-align: middle;
        width: 1.5rem;
    }
}